import React from 'react'
import Layout from "../components/layout"
import flyerCenter from "../images/sample-flyer.webp"
import flyerLeft from "../images/sample-flyer-front.webp"
import flyerRight from "../images/sample-flyer-back.webp"
import communityTop from "../images/community-top.webp"
import communityLeft from "../images/community-left.webp"
import communityRight from "../images/community-right.webp"
import droneCenter from "../images/drone.webp"
import { Link } from 'gatsby';

const PhotosPage = () => (
    <Layout siteTitle="Photos">
        <div className="bg-gray-200">
            <h1 className="font-bold text-7xl text-center py-12 uppercase">Photos</h1>
            
        
            <div className="w-full bg-white h-auto grid xl:grid-cols-2 xl:grid-rows-1 grid-rows-3 grid-flow-row p-5 mb-10 gap-5 justify-items-center">
                <div className="space-y-10 max-w-xl">
                    <h3 className="text-blue-400 text-5xl font-bold">Listing Flyer</h3>
                    <h4 className="text-blue-400 text-3xl font-bold">Add A Listing Flyer For Faster Sales</h4>
                    <p className="text-2xl">
                        A beautiful flyer helps sell a home faster, which is why our flyers are designed to highlight the best parts of your listing. 
                        Our flyers also contain QR codes that allow potential customers to view your virtual tour of the property with a quick scan.
                    </p>
                    <p className="text-2xl pb-12">
                        “Having flyers available for your open house not only provides consumers with more information to help keep your listing top of mind, 
                        but they also show potential sellers who might be visitors the quality of your marketing materials.”
                    </p>
                    <Link external 
                        to="https://knockoutphoto.hd.pics/view/default.asp?s=365600" 
                        className="bg-blue-500 text-white font-bold p-3 hover:bg-blue-300">
                            View Prices Now
                    </Link>
                </div>

                <div className="w-full sm:h-full flex justify-center relative max-w-xl max-h-96" style={{height : "200px"}}>
                    <img alt="flyer" className="w-1/3 md:w-3/5  absolute z-10 shadow-lg mt-24 mr-44 sm:mr-96" style={{"transform" : "rotate(-5deg)"}} src={flyerLeft} />
                    <img alt="flyer" className="w-1/3 md:w-3/5  absolute z-20 shadow-lg mt-28" style={{"transform" : "rotate(5deg)"}}  src={flyerCenter} />
                    <img alt="flyer" className="w-1/3 md:w-3/5  absolute z-10 shadow-lg mt-24 ml-44 sm:ml-96" style={{"transform" : "rotate(-1deg)"}}  src={flyerRight} />
                </div>
                
            </div>

            <div className="w-full bg-white h-auto grid xl:grid-cols-2 xl:grid-rows-1 grid-rows-2 grid-flow-row p-5 gap-5 mb-10 justify-items-center">
                <div className="space-y-10 max-w-xl">
                    <h3 className="text-blue-400 text-5xl font-bold">Community Photos</h3>
                    <h4 className="text-blue-400 text-3xl font-bold">Showcase the amenities offered in the community</h4>
                    <p className="text-2xl pb-12">
                        Community photos provide a potential customer a glimpse of the neighborhood their next home will be in. 
                        These photos include shots of any community pools, clubhouses, schools, gyms, etc. 
                        Around 6 photos are delivered to you, and we only take photos within a 15 minute timeframe, including driving.
                    </p>
                    <Link external 
                        to="https://knockoutphoto.hd.pics/view/default.asp?s=365600" 
                        className="bg-blue-500 text-white font-bold p-3 hover:bg-blue-300">
                            View Prices Now
                    </Link>
                </div>

                <div className="w-full sm:h-full h-auto flex justify-center relative max-w-xl">
                    <img alt="community" className="h-1/5 sm:h-3/5 z-10 absolute shadow-lg mt-24 mr-72 sm:mr-96" style={{"transform" : "rotate(6deg)"}} src={communityLeft} />
                    <img alt="community" className="h-1/5 sm:h-3/5 z-0 absolute shadow-lg mt-12" style={{"transform" : "rotate(-5deg)"}} src={communityTop} />
                    <img alt="community" className="h-1/5 sm:h-3/5 z-10 absolute shadow-lg mt-24 ml-72 sm:ml-96" style={{"transform" : "rotate(-10deg)"}} src={communityRight} />
                    
                </div>
                
            </div>

            <div className="w-full bg-white h-auto grid xl:grid-cols-2 xl:grid-rows-1 grid-rows-1 grid-flow-row p-5 gap-5 mb-10 justify-items-center">
                <div className="space-y-10 max-w-xl">
                    <h3 className="text-blue-400 text-5xl font-bold">3D Virtual Tour</h3>
                    <h4 className="text-blue-400 text-3xl font-bold">A virtual walkthrough of the property</h4>
                    <p className="text-2xl pb-12">
                        Your buyers can immerse themselves into the property virtually from their 
                        computer and explore the house from the exterior and interior and explore every room of the property.
                    </p>
                    <Link external 
                        to="https://knockoutphoto.hd.pics/view/default.asp?s=365600" 
                        className="bg-blue-500 text-white font-bold p-3 hover:bg-blue-300">
                            View Prices Now
                    </Link>
                </div>

                <div className="w-full h-full max-w-xl">
                <iframe 
                    style={{width: "100%", height: "400px", border: "none", maxWidth: "100%"}}
                    frameborder="0" 
                    title="Tour"
                    allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" 
                    scrolling="no" 
                    src="https://kuula.co/share/collection/7PFt5?fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1&info=0&logo=-1">    
                </iframe>
                </div>
                
            </div>

            <div className="w-full bg-white h-auto grid xl:grid-cols-2 xl:grid-rows-1 grid-rows-1 grid-flow-row p-5 gap-5 mb-10 justify-items-center">
                <div className="space-y-10 max-w-xl">
                    <h3 className="text-blue-400 text-5xl font-bold">Drone Photos</h3>
                    <h4 className="text-blue-400 text-3xl font-bold">Capture a bird's eye view of the property</h4>
                    <p className="text-2xl">
                        Our drone photos capture your listing from a bird's-eye view, allowing potential customers to see every square foot of the property. 
                    </p>
                    <p className="text-2xl pb-12">
                    Our drone package includes 10 photos taken from the exterior of the property including the front of the 
                    house, sides, directly above, backyard, and the neighborhood view. 
                    </p>
                    <Link external 
                        to="https://knockoutphoto.hd.pics/view/default.asp?s=365600" 
                        className="bg-blue-500 text-white font-bold p-3 hover:bg-blue-300">
                            View Prices Now
                    </Link>
                </div>

                <div className="w-full h-full flex content-between justify-evenly">
                    <img alt="drone" src={droneCenter} />
                </div>
                
            </div>

            <div className="px-16 py-10 grid space-y-5 bg-white">
                <h3 className="font-bold text-3xl justify-self-center">Cancellation or Rescheduling</h3>
                <p className="text-2xl justify-self-center">
                    Given at least 1 day's notice, there is no charge for either cancellation or rescheduling.<br/>
                    For a same-day cancellation or rescheduling, there is a cancellation fee of $50 for daylight shoots and $100 for twilight shoots.
                </p>
            </div>
        </div>
    </Layout>
)

export default PhotosPage